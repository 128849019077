<mat-list-item
  routerLinkActive="active"
  #rla="routerLinkActive"
  class="custom"
>
  <a
    class="item-container"
    [routerLink]="item.routerLink"
    [queryParams]="item.queryParams"
    ><mat-icon
      class="icons"
      aria-hidden="false"
      attr.aria-label="{{ item.ariaLabel }} icon"
      [svgIcon]="rla.isActive ? item.activeIcon : item.inactiveIcon"
      [ngClass]="!rla.isActive && item.inactiveClass ? item.inactiveClass : ''"
    >
    </mat-icon>
    @if (isExpanded) {
      <span class="menu-item">{{ item.label }}</span>
    }
  </a>
</mat-list-item>
