import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';

import { SidenavItemModel } from './models/sidenav-item.model';

@Component({
  selector: 'dmc-ng-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatListModule, MatIconModule, RouterModule],
  standalone: true,
})
export class SidenavItemComponent {
  @Input() item!: SidenavItemModel;
  @Input() isExpanded = false;
}
