<mat-sidenav-container
  class="sidenav-container"
  autosize
>
  <mat-sidenav
    #sidenav
    class="sidenav"
    mode="side"
    opened="isExpanded"
    [disableClose]="true"
  >
    <mat-toolbar>
      <ng-content select="[toolbar]"></ng-content>
    </mat-toolbar>
    <mat-nav-list>
      <ng-content select="[collapse]"></ng-content>
      <ng-content select="[items]"></ng-content>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content select="[content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
